<template>
  <div class="aipic-chat">
    <Header
      class="app-title"
      callBack
      @click="goBack"
      v-if="!this.$route.query.platform"
    >
      <div>{{ this.$route.query.name }}</div>
    </Header>
    <div class="container" id="chatBox" :style="{ top: conTop }">
      <div class="chat-parents">
        <div v-for="(item, index) in data" :key="index">
          <div class="me-div">
            <div class="me-img"><img :src="userUrl" /></div>
            <div class="me-text" id="me-text">{{ item.q }}</div>
            <div class="clear"></div>
          </div>
          <div class="bot-div">
            <div class="bot-img"><img :src="botUrl" /></div>
            <div class="bot-text" id="bot-text">{{ item.a }}</div>
            <div class="clear"></div>
          </div>
        </div>
      </div>
      <div class="none-div"></div>
    </div>
    <div class="chat-div">
      <div>
        <div class="send-inp">
          <input
            placeholder="请输入消息......"
            :disabled="isDisabled"
            v-model="prompt"
            @keyup.enter="submit"
          />
        </div>
        <div class="send-btn" @click="submit">发送</div>
        <div class="clear"></div>
      </div>
    </div>
  </div>
</template>
<script>
import Axios from 'axios'

export default {
  name: 'aipic',
  data() {
    return {
      aipicUrl: '',
      botUrl: '',
      userUrl: '',
      prompt: '',
      data: [],
      conTop: 0,
      isDisabled: false,
      token: '',
      positionId: 0,
    }
  },
  methods: {
    goBack() {
      if (this.$route.query.platform) {
        if (this.isAndroid) {
          // eslint-disable-next-line no-undef
          control.jumptoapp(JSON.stringify({ key: -2 }))
        } else {
          this.bridge.callHandler(
            'jumptoapp',
            { key: -2 },
            function (response) {
              console.log(JSON.stringify(response))
            }
          )
        }
      } else {
        this.$router.back()
      }
    },
    async submit() {
      // if(this.data.length >= this.maxChat){
      //     this.$toast('发送失败，每天最多可发送5条消息');
      //     return
      // }
      //获取可聊天条数
      let token = this.token
      try {
        let resNum = await this.$api.catUserAllowNum({
          token: token,
          positionId: this.positionId,
        })
        if (resNum.code != 0) {
          this.$toast(resNum.msg)
          return
        } else {
          if (resNum.num <= 0) {
            this.$toast(
              '发送失败，已达今日聊天上限，赠送礼物可提高亲密度与我聊天哦～'
            )
            return
          } else {
            //获取内容
            let prompt = this.$data.prompt
            if (prompt.length > 0) {
              this.isDisabled = true
              try {
                let data1 = {}
                data1.q = prompt
                data1.a = '对方输入中...'
                this.data.push(data1)
                this.scrollToBottom()
                this.prompt = ''

                // let response = await Axios.post('http://127.0.0.1:3000/ai/createCompletion', { prompt: prompt });
                let response = await Axios.post('/ai/createCompletion', {
                  prompt: prompt,
                })
                this.isDisabled = false
                if (response.status == 200) {
                  let data2 = {}
                  if (typeof response.data.text == 'string') {
                    data2.q = prompt
                    data2.a = response.data.text
                    //向数据库发送请求
                    this.addUserAiSay(prompt, response.data.text)
                  } else {
                    data2.q = prompt
                    data2.a = '内容无法识别'
                  }
                  this.data.pop()
                  this.data.push(data2)
                  this.scrollToBottom()
                } else {
                  this.$toast('输入内容无法识别')
                }
              } catch (error) {
                this.$toast('请求失败')
              }
            } else {
              this.$toast('请输入内容')
            }
          }
        }
      } catch (error) {
        this.$toast('登录状态异常')
        return
      }
    },
    async addUserAiSay(content, ai) {
      let token = this.token
      //查询拥有聊天条数
      let res = await this.$api.addUserAiSay({
        token: token,
        content: content,
        ai: ai,
        positionId: this.positionId,
      })
      console.log(res)
    },
    scrollToBottom() {
      this.$nextTick(() => {
        let container = document.getElementById('chatBox')
        container.scrollTop = container.scrollHeight
      })
    },
    setTop() {
      if (this.$route.query.platform) {
        this.conTop = 0
      } else {
        this.conTop = '50px'
      }
    },
  },
  mounted() {
    this.botUrl = this.$route.query.url
    this.userUrl = this.$route.query.userUrl
    this.token = this.$route.query.token
    this.positionId = this.$route.query.positionId
    this.scrollToBottom()
    this.setTop()
  },
}
</script>
<style lang="less" scoped>
.app-title {
  z-index: 10;
}
.container {
  position: fixed;
  background: #f3f4f8;
  margin: auto;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  font-size: 15px;
  line-height: 25px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #333333;
}
.chat-parents {
  margin: 0 15px;
}
.me-div,
.bot-div {
  overflow: hidden;
  zoom: 1;
  margin-top: 24px;
}
.me-img,
.bot-img {
  height: 39px;
}
.me-img img,
.bot-img img {
  width: 39px;
  height: 39px;
  border-radius: 39px;
}
.me-div > div {
  float: right;
}
.bot-div > div {
  float: left;
}
.clear {
  clear: both;
}
.me-text,
.bot-text {
  margin: 0 10px;
  height: 42;
  max-width: 72%;
}
.me-text {
  border-radius: 21px 0px 21px 21px;
  background-color: #fff;
  padding: 10px 15px;
  box-sizing: border-box;
}
.bot-text {
  border-radius: 0px 21px 21px 21px;
  background: #ffe4f0;
  padding: 10px 15px;
  box-sizing: border-box;
}
.chat-div {
  position: fixed;
  width: 100%;
  height: 81px;
  bottom: 0px;
  background: #ececec;
  > div {
    margin: 11px 15px;
    overflow: hidden;
    zoom: 1;
  }
}
.send-inp {
  height: 39px;
  width: 85%;
  float: left;
  display: flex;
}
.send-inp > input {
  background: #ffffff;
  border-radius: 20px;
  width: 100%;
  height: 39px;
  background: #ffffff;
  border: 0;
  font-size: 15px;
  padding: 0 17px;
  box-sizing: border-box;
}
.send-btn {
  float: right;
  width: 10%;
  font-size: 15px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #ff54a6;
  line-height: 39px;
}
.none-div {
  height: 100px;
}
</style>
